import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, updateInterval } from '../../store';

const ClockModule: React.FC = () => {
    const [time, setTime] = useState<Date>(new Date());
    const isFullScreen = useSelector((state: RootState) => state.fullScreen.isFullScreen);
    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date());
            // Check if time is exactly on a 5-minute mark
            if (new Date().getMinutes() % 5 === 0 && new Date().getSeconds() === 0) {
                dispatch(updateInterval());
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [dispatch]);

    // Function to format the time and separate the AM/PM part
    const formatTime = (date: Date) => {
        const options: Intl.DateTimeFormatOptions = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        const timeString = date.toLocaleTimeString(undefined, options);
        const [time, period] = timeString.split(' ');
        return { time, period };
    };

    const { time: formattedTime, period } = formatTime(time);

    return (
        <div className="time-wrapper">
            <p className={isFullScreen ? "fullscreen" : ""}>
                {formattedTime} <span className="ampm">{period}</span>
            </p>
        </div>
    );
};

export default ClockModule;

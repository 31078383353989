import React, { useState, useEffect } from "react";
import { Quote } from "../../utils/types";
import { quotes } from "../../utils/quotes";
import { useSelector } from 'react-redux';
import { RootState } from "../../store";

const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
};

const QuoteModule = () => {
    // Use spread operator to convert iterator to array
    const [quoteIndices, setQuoteIndices] = useState(() => shuffleArray([...Array(quotes.length).keys()]));
    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
    const lastUpdate = useSelector((state: RootState) => state.intervalUpdate.lastUpdate);

    const getNextQuoteIndex = () => {
        if (currentQuoteIndex >= quoteIndices.length - 1) {
            // Reshuffle and reset if at the end of the cycle
            setQuoteIndices(shuffleArray([...Array(quotes.length).keys()]));
            return 0;
        } else {
            return currentQuoteIndex + 1;
        }
    };

    useEffect(() => {
        setCurrentQuoteIndex(getNextQuoteIndex);
    }, [lastUpdate]);

    const quote: Quote = quotes[quoteIndices[currentQuoteIndex]];

    return (
        <div className="quote-wrapper">
            <h1>{quote.text}</h1>
            <h2>— {quote.author}</h2>
            {quote.source && <a href={quote.source} target="_blank" rel="noreferrer">Source</a>}
        </div>
    );
};

export default QuoteModule;

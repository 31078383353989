import { Quote } from "./types";

export const quotes: Quote[] = [
    {
        id: "1",
        text: "If you want the future to be good, you must make it so. Take action to make it good, and it will be.",
        author: "Elon Musk",
        source: "https://youtu.be/YRvf00NooN8?t=3960"
    },
    {
        id: "2",
        text: "Optimism, pessimism, f*ck that; we're going to make it happen. As God is my bloody witness, I'm hell-bent on making it work.",
        author: "Elon Musk",
        source: "https://www.wired.com/2008/08/musk-qa/"
    },
    {
        id: "3",
        text: "People are mistaken when they think that technology just automatically improves. It does not automatically improve. It only improves if a lot of people work very hard to make it better.",
        author: "Elon Musk",
        source: "https://youtu.be/zIwLWfaAg-8?t=2251"
    },
    {
        id: "4",
        text: "I will fail many times, and I will be really right once is the entrepreneurs' way. You have to give yourself a lot of chances to get lucky.",
        author: "Sam Altman",
        source: "https://blog.samaltman.com/how-to-be-successful"
    },
    {
        id: "5",
        text: "Once you have figured out what to do, be unstoppable about getting your small handful of priorities accomplished quickly. I have yet to meet a slow-moving person who is very successful.",
        author: "Sam Altman",
        source: "https://blog.samaltman.com/how-to-be-successful"
    },
    {
        id: "6",
        text: "Almost always, the people who say \"I am going to keep going until this works, and no matter what the challenges are I'm going to figure them out\", and mean it, go on to succeed. They are persistent long enough to give themselves a chance for luck to go their way.",
        author: "Sam Altman",
        source: "https://blog.samaltman.com/how-to-be-successful"
    },
    {
        id: "7",
        text: "If you dislike change, you're going to dislike irrelevance even more.",
        author: "Eric Shinseki",
        source: "https://en.wikiquote.org/wiki/Eric_Shinseki"
    },
    {
        id: "8",
        text: "Any sufficiently advanced technology is indistinguishable from magic.",
        author: "Arthur C. Clarke",
        source: "https://en.wikipedia.org/wiki/Clarke%27s_three_laws"
    },
    {
        id: "9",
        text: "Computer science is no more about computers than astronomy is about telescopes, biology is about microscopes or chemistry is about beakers and test tubes. Science is not about tools. It is about how we use them, and what we find out when we do.",
        author: "Edsger Dijkstra",
        source: "https://youtu.be/Gfr50f6ZBvo?t=7815"
    },
    {
        id: "10",
        text: "It's hard to do a really good job on anything you don't think about in the shower.",
        author: "Paul Graham",
        source: "http://www.paulgraham.com/top.html"
    },
    {
        id: "11",
        text: "A fit body, a calm mind, a house full of love. These things cannot be bought - they must be earned.",
        author: "Naval Ravikant",
        source: "https://twitter.com/naval/status/966512979066765313"
    },
    {
        id: "12",
        text: "Freedom is a pure idea. It occurs spontaneously and without instruction. - Tyranny requires constant effort. It breaks, it leaks. Authority is brittle. Oppression is the mask of fear.",
        author: "Nemik's Manifesto",
        source: "https://youtu.be/aoJILDr94qc"
    },
    {
        id: "13",
        text: "Everything around you that you call life was made up by people that were no smarter than you. - And you can change it, you can influence it, you can build your own things that other people can use.",
        author: "Steve Jobs",
        source: "https://twitter.com/anammostarac/status/1595931586973007873"
    },
    {
        id: "14",
        text: "The lesson from “tortoise & hare” is not that tortoises are faster, but rather that hares should not be complacent",
        author: "Elon Musk",
        source: "https://twitter.com/elonmusk/status/1438780252474560513"
    },
    {
        id: "15",
        text: "You cannot swim for new horizons until you have courage to lose sight of the shore.",
        author: "William Faulkner",
        source: "https://twitter.com/lexfridman/status/1404953255759814657"
    },
    {
        id: "16",
        text: "When something is important enough, you do it even if the odds are not in your favor.",
        author: "Elon Musk",
        source: "https://youtu.be/ViOdlRzq3MY"
    },
    {
        id: "17",
        text: "Having customers will fix any motivation problems you have.",
        author: "Sahil Lavingia",
        source: "https://twitter.com/shl/status/1349098597476757504"
    },
    {
        id: "18",
        text: "Ask for feedback on your attempts, not advice on your ideas.",
        author: "Sahil Lavingia",
        source: "https://twitter.com/shl/status/1313607008537387008"
    },
    {
        id: "19",
        text: "All of physics is either impossible or trivial. It is impossible until you understand it, and then it becomes trivial.",
        author: "Ernest Rutherford",
        source: "https://twitter.com/elonmusk/status/1299430971494731776"
    },
    {
        id: "20",
        text: "The cosmos is also within us. We're made of star-stuff. We are a way for the cosmos to know itself.",
        author: "Carl Sagan",
        source: "https://en.wikiquote.org/wiki/Carl_Sagan"
    },
    {
        id: "21",
        text: "Liberty consists in the division of power. Absolutism, in concentration of power.",
        author: "John Dalberg-Acton",
        source: "https://twitter.com/elonmusk/status/1759302582801985647"
    },
];
import React from "react";
import Particle from "../components/particles/Particle";
import ClockModule from "./modules/ClockModule";
import QuoteModule from "./modules/QuoteModule";
import ExpandModule from "./modules/ExpandModule";

export const Home = () => {

    return (
        <div id="home">
            <div className="home-content">

                <div className="bar header">
                    <div className="section left">
                        <div className="block">

                        </div>
                    </div>

                    <div className="section right">
                        <div className="block">
                            <ClockModule/>
                        </div>
                    </div>
                </div>


                <div className="center-body">
                    <QuoteModule/>
                </div>

                <div className="bar footer">
                    <div className="section left">
                        <div className="block">

                        </div>
                    </div>

                    <div className="section right">
                        <div className="block">
                            <ExpandModule/>
                        </div>
                    </div>
                </div>

            </div>
            <div className="home-bg">
                <Particle/>
            </div>
        </div>
    );
};
import { useEffect } from "react";
import { Home } from "./components/Home";

const App = () => {

    useEffect(() => {
        document.title = 'zensaver.co';
    }, []);

    return (
        <Home/>
    );
};

export default App;

import { configureStore, createSlice } from '@reduxjs/toolkit';

export type RootState = ReturnType<typeof store.getState>;

// ----------------------------------------------------------------------------------
// -------------------------------- FULLSCREEN SLICE --------------------------------
// ----------------------------------------------------------------------------------
const initialFullscreenState = {
    isFullScreen: false,
};
const fullScreenSlice = createSlice({
    name: 'fullScreen',
    initialState: initialFullscreenState,
    reducers: {
        toggleFullScreen: state => {
            state.isFullScreen = !state.isFullScreen;
        },
    },
});
export const {toggleFullScreen} = fullScreenSlice.actions;

// ----------------------------------------------------------------------------------
// --------------------------------- INTERVAL SLICE ---------------------------------
// ----------------------------------------------------------------------------------
const initialIntervalUpdateState = {
    lastUpdate: new Date(),
};
const intervalUpdateSlice = createSlice({
    name: 'intervalUpdate',
    initialState: initialIntervalUpdateState,
    reducers: {
        updateInterval: state => {
            state.lastUpdate = new Date();
        },
    },
});
export const {updateInterval} = intervalUpdateSlice.actions;


// Configure the store
const store = configureStore({
    reducer: {
        fullScreen: fullScreenSlice.reducer,
        intervalUpdate: intervalUpdateSlice.reducer,
    },
});
export default store;

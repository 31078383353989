import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { toggleFullScreen } from "../../store";
import { RootState } from '../../store';

const ExpandModule = () => {
    const isFullScreen = useSelector((state: RootState) => state.fullScreen.isFullScreen);
    const doc = document as any; // Using 'any' to bypass TypeScript checks for prefixed methods
    const docEl = (document.documentElement as any);
    const dispatch = useDispatch();

    const handleFullScreenChange = () => {
        const isCurrentlyFullScreen = doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement || doc.msFullscreenElement;
        // Dispatch action to update state only if current state doesn't match actual fullscreen status
        if ((isCurrentlyFullScreen && !isFullScreen) || (!isCurrentlyFullScreen && isFullScreen)) {
            dispatch(toggleFullScreen());
        }
    };

    useEffect(() => {
        doc.addEventListener("fullscreenchange", handleFullScreenChange);
        doc.addEventListener("webkitfullscreenchange", handleFullScreenChange);
        doc.addEventListener("onfullscreenchange", handleFullScreenChange);
        doc.addEventListener("MSFullscreenChange", handleFullScreenChange);

        return () => {
            doc.removeEventListener("fullscreenchange", handleFullScreenChange);
            doc.removeEventListener("webkitfullscreenchange", handleFullScreenChange);
            doc.removeEventListener("onfullscreenchange", handleFullScreenChange);
            doc.removeEventListener("MSFullscreenChange", handleFullScreenChange);
        };
    }, [isFullScreen]);

    const onClick = () => {
        if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
            // Enter fullscreen
            if (docEl.requestFullscreen) {
                docEl.requestFullscreen();
            } else if (docEl.mozRequestFullScreen) {
                docEl.mozRequestFullScreen();
            } else if (docEl.webkitRequestFullscreen) {
                docEl.webkitRequestFullscreen();
            } else if (docEl.msRequestFullscreen) {
                docEl.msRequestFullscreen();
            }
        } else {
            // Exit fullscreen
            if (doc.exitFullscreen) {
                doc.exitFullscreen();
            } else if (doc.mozCancelFullScreen) {
                doc.mozCancelFullScreen();
            } else if (doc.webkitExitFullscreen) {
                doc.webkitExitFullscreen();
            } else if (doc.msExitFullscreen) {
                doc.msExitFullscreen();
            }
        }
    };

    return (
        <div className="expand-wrapper" onClick={onClick}>
            <div className={"icon expand" + (isFullScreen ? " fullscreen" : "")}/>
        </div>
    );
};

export default ExpandModule;
